<template lang="pug">
    .main-wrapper.operadora-listar
        header.main-wrapper-header
            .p-grid.p-align-center
                .p-col-12.ta-right
                    h1.text-header.text-secondary MedClub / <b>Operadoras</b>


        Dialog(header='Remover operadora' :visible.sync='dialogApagar' :modal='true')
            p Deseja remover a operadora <b>{{ dialogApagar_data.ds_operadora }}</b>?
            .ta-center.my-4
                Button.p-button-danger(label='Remover' @click='excluir(dialogApagar_data.id)')


        Panel.mb-2(header='Filtros' :toggleable='true' :collapsed='windowInnerWidth < 576')
            .p-grid.p-fluid.p-align-end
                .p-col-12.p-md-4
                    label.form-label Operadora:
                    .p-inputgroup
                        InputText(
                            placeholder='Operadora'
                            :autoClear='false'
                            @keyup.enter.native='applyFilters()'
                            @input='val => val || applyFilters()'
                            v-model='filters.ds_operadora'
                        )
                        
                .p-col-12.p-md-4
                    label.form-label Posto(s):
                    MultiSelect( v-model='filters.unidades' :options='filtersOptions.unidades'
                                    dataKey='value' optionLabel='text' optionValue='value' 
                                    placeholder='Selecione' :filter='filtersOptions.unidades.length > 0'
                                    @change='applyFilters()')
                .p-col-12.p-md-4
                    label.form-label Status:
                    Dropdown(
                        v-model='filters.ie_status'
                        :options='filtersOptions.ieStatus'
                        optionLabel='text'
                        optionValue='value'
                        dataKey='value'
                        placeholder='TODOS'
                        @change='applyFilters()'
                    )
                .p-col-12.p-md-12.ta-right
                    Button.addButton(label='Limpar filtros' type='button' icon='jam jam-rubber' @click='clearFilters()')

        .ta-right.mb-4
            Button(label='Adicionar' icon="jam jam-plus" @click="editar(0)")

        ProgressBar(v-if='waiting' mode="indeterminate")

        div(v-else-if='!list.length')
            p.ta-center.text-secondary(style='margin-top: 40px;') Nenhum registro encontrado.

        div(v-else)
    

            Paginator.dataview(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")

            Panel.datatable(header='Lista de Operadora')
                DataTable(:value="list" @sort="onSort($event)" :sortField="order.field" :sortOrder="order.sortOrder")
                    Column(headerStyle='width: 5%;' bodyStyle='text-align: center;')
                        template(#body='props')
                            ProgressSpinner.spinner-small(v-if='waitingStatusId === props.data.id' strokeWidth='6')
                            .status-indicator(v-else :class="{ off: ! props.data.ie_status }" @click='alterarStatus(props.data)')
                                i.jam(:class="{ 'jam-check': props.data.ie_status, 'jam-minus': !props.data.ie_status }")
                    Column(headerStyle='width: 15%;' bodyStyle='text-align: center; text-width: bold' field='ds_operadora' header='Operadora')
                         template(#body='props')
                            span <b> {{props.data.ds_operadora}} </b>
                    Column(headerStyle='width: 25%;' bodyStyle='text-align: center' header='Postos')
                        template(#body='props')
                            div(v-for='unidade in props.data.unidades')                                 
                                span <li><b> {{unidade}} </b></li>
                    Column(headerStyle='width: 40%;' field='ds_observacao' bodyStyle='text-align: center' header='Observação')
                        template(#body='props')
                            span <b> {{props.data.ds_observacao}} </b>
                    Column(headerStyle='widthx: 15%;' header='Ações')
                        template(#body='props')
                            .ta-center
                                Button.p-button-raised.p-button-rounded.mr-1(
                                    v-tooltip.top="'Editar'"
                                    icon="jam jam-write"
                                    @click="editar(props.data.id)"
                                )
                                Button.p-button-raised.p-button-rounded.p-button-danger.mr-1(
                                    v-tooltip.top="'Excluir'"
                                    icon="jam jam-minus-circle"
                                    @click="dialogApagar_data=props.data;dialogApagar=true"
                                )
                Paginator(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")

</template>

<style lang="scss">
    .operadora-listar {
        .addButton {
            max-width: 200px;
        }
        .dataview {
            @media all and (min-width: 577px) {
                display: none;
            }
        }
        .datatable {
            @media all and (max-width: 576px) {
                display: none;
            }
        }
        .dialog-salvar {
            width: 600px;
            max-width: 98%;
        }
        .spinner-small {
            max-width: 23px;
            height: auto;
        }
        .status-indicator {
            display: inline-block;
            font-size: 0;
            width: 23px;
            height: 23px;
            line-height: 36px;
            border-radius: 50%;
            background-color: #94c860;
            border: 1px solid #84b553;
            transition: 250ms;
            cursor: pointer;
            padding-right:10px;
            &:hover { background-color: #84b553; }
            &.off {
                background-color: #d33838;
                border: 1px solid #b33030;
                &:hover { background-color: #b33030; }
            }
            .jam {
                color: #fff;
                margin-left:2px;
            }
        }
        .p-multiselect-filter-container {
            width: 85% !important;
        }
        .dialogApagar {
            .p-progress-spinner {
                width: 29px;
                height: auto;
            }
	}
    }
</style>

<script>
    import Button from "primevue/button"
    import Column from "primevue/column"
    import DataTable from "primevue/datatable"
    import DataView from "primevue/dataview"
    import Dialog from "primevue/dialog"
    import Dropdown from 'primevue/dropdown'
    import MultiSelect from 'primevue/multiselect'
    import InputText from 'primevue/inputtext'
    import Paginator from 'primevue/paginator'
    import Panel from "primevue/panel"
    import ProgressBar from "primevue/progressbar"
    import ProgressSpinner from 'primevue/progressspinner'
    import Tooltip from "primevue/tooltip"

    import wsConfigs from "@/middleware/configs"
    import {Agendamento, UnidadesMedclub, Operadora} from "../../middleware"
    // import moment from "moment"

    export default {
        components: { Button, Column, DataTable, DataView, Dialog, Dropdown, InputText,
            Paginator, Panel, ProgressBar, ProgressSpinner, Tooltip, MultiSelect },
        directives: { tooltip: Tooltip },
        created() {

            this.applyFilters()
            Operadora.findAll().then(response => {
                if(([200, 201]).includes(response.status)){
                    this.list = response.data;
                    
                }
            })
            UnidadesMedclub.findAll().then(response => {
                if(([200, 201]).includes(response.status)){
                    this.filtersOptions.unidades = response.data.map(item => ({
                        ...item,
                        value: item.id,
                        text:  item.nm_unidade
                    }));
                }
            })
        },
        data() {
            return {
                
                list: [],
                windowInnerWidth: window.innerWidth,
                submitted: false,
                waiting: false,
                waitingSalvar: false,
                dialogSalvar: false,
                dialogApagar: false,
                dialogApagar_data: {ds_operadora: ''},
                waitingStatusId: null,
                filters: {
                    ds_origem: "",
                    unidades:[],
                    ie_status: null
                },
                filtersOptions: {
                    ieStatus: [
                        { text: "TODOS", value: null },
                        { text: "Ativo", value: true },
                        { text: "Inativo", value: false },
                    ],
                    unidades: []
                },
                order: {
                    field: 'ds_operadora',
                    sortOrder: 1
                },
                paginator: {
                    page: this.$route.query.pg || 1,
                    per_page: wsConfigs.paginator_perPage,
                    count: 0
                },             
                mock: [
                    {ds_operadora: "Cielo",
                    unidades: [3, 5, 7],
                    ds_observacao: "TESTE",
                    ie_status:true},
                    
                    {ds_operadora: "Credishop",
                    unidades: [1, 5, 6],
                    ds_observacao: "TESTE",
                    ie_status: true}
                    
                ]
            }
        },
      
        watch: {
            dialogSalvar: function (val) {
                if (val === false) this.submitted = false
            },
        },
        methods: {
            getList(params) {
                this.waiting = true
                Operadora.findAll(params).then(response => {
                    if(([200,201].includes(response.status))){
                        this.paginator.count = response.data.count
                        this.list = response.data.results
                    }
                })
                // Agendamento.origemAtendimentoFindAll(params).then(response => {
                //     this.waiting = false
                //     if (response.status === 200) {
                //         this.paginator.count = response.data.count
                //         this.list = response.data.results
                //         this.list.forEach(origem => {
                //             origem.cd_usuario_cri = origem.cd_usuario_cri || {}
                //             origem.cd_usuario_edi = origem.cd_usuario_edi || {}
                //             if (origem.dt_criado) {
                //                 origem.dt_criado_f = moment(origem.dt_criado).format('DD/MM/YYYY HH:mm')
                //             }
                //             if (origem.dt_atualizado) {
                //                 origem.dt_atualizado_f = moment(origem.dt_atualizado).format('DD/MM/YYYY HH:mm')
                //             }
                //         })
                //     } else if (response.data.detail) {
                //         if (typeof response.data.detail == 'string')
                //             this.$toast.error(response.data.detail, { duration: 3000 })
                //         else response.data.detail.forEach(msg => this.$toast.error(msg, { duration: 3000 }))
                //     }
                // })
            this.waiting = false
            },
            applyFilters() {
                if (this.$route.query.pg != this.paginator.page) this.$router.replace( { query: { pg: this.paginator.page } } )

                let params = { paginacao: true, page: this.paginator.page, per_page: this.paginator.per_page }
                params.order = `${ this.order.sortOrder === 1 ? '' : '-' }${ this.order.field }`

                Object.keys(this.filters).forEach((key) => {
                    if (this.filters[key] !== null && this.filters[key] !== "") params[key] = this.filters[key]
                })
                this.getList(params)
            },
            salvarOrigem() {
                this.submitted = true

                this.$v.$touch()
                if (this.$v.$invalid) return 0

                let dataSend = { ds_origem: this.model.ds_origem }
                if (this.model.id) dataSend.id = this.model.id

                this.waitingSalvar = true
                Agendamento.origemAtendimentoSave(dataSend).then(response => {
                    this.waitingSalvar = false
                    if (response.status === 200) {
                        this.$toast.success("Origem salva com sucesso.")
                        this.dialogSalvar = false
                        this.model = { ds_origem: "" }
                        this.applyFilters()
                    } else if (response.data.detail) {
                        if (typeof response.data.detail == 'string')
                            this.$toast.error(response.data.detail, { duration: 3000 })
                        else response.data.detail.forEach(msg => this.$toast.error(msg, { duration: 3000 }))
                    }
                })
            },
            alterarStatus(origem) {
                let dataSend = { id: origem.id, ie_status: !origem.ie_status }
                this.waitingStatusId = origem.id;
                Operadora.save(dataSend).then(response => {
                    this.waitingStatusId = null;
                    if(([200,201]).includes(response.status)){
                        origem.ie_status = !origem.ie_status;
                        this.$toast.success("Status alterado com sucesso.")
                    }else{
                         if (typeof response.data.detail == 'string')
                            this.$toast.error(response.data.detail, { duration: 3000 })
                        else response.data.detail.forEach(msg => this.$toast.error(msg, { duration: 3000 }))
                    }
                })
                // Agendamento.origemAtendimentoSave(dataSend).then(response => {
                //     this.waitingStatusId = 0
                //     if (response.status === 200) {
                //         origem.ie_status = !origem.ie_status
                //         this.$toast.success("Status alterado com sucesso.")
                //     } else if (response.data.detail) {
                //         if (typeof response.data.detail == 'string')
                //             this.$toast.error(response.data.detail, { duration: 3000 })
                //         else response.data.detail.forEach(msg => this.$toast.error(msg, { duration: 3000 }))
                //     }
                // })
            },
            onPage (ev) {
                this.paginator.page = ev.page + 1
                this.applyFilters()
            },
            onSort(ev) {
                this.order.field = ev.sortField
                this.order.sortOrder = ev.sortOrder
                this.applyFilters()
            },
            editar(id){
                this.$router.push(`/operadora/salvar/${id}`);
            },
            excluir(id){
                Operadora.remove(id).then(response => {
                    if(([200,201,204]).includes(response.status)){
                        this.$toast.info('Paciente removido com sucesso', { duration: 3000 })
                        this.dialogApagar = false;
                        this.$router.go(0);
                    }else if (response.data.detail) {
					if (typeof response.data.detail == 'string')
						this.$toast.error(response.data.detail, { duration: 3000 })
					else response.data.detail.forEach(msg => this.$toast.error(msg, { duration: 3000 }))
				}
                       
                })
            },
            clearFilters(){
                this.filters = {
                    ds_origem: "",
                    unidades:[],
                    ie_status: null
                }
            },
        },
    }
</script>
